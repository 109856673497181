import React from 'react'
import Layout from '../layouts'
import './index.css'
import { Link } from 'gatsby'
import CommonHero from '../components/commonhero/commonhero.js'
import clientStories from '../data/clientStories.json'
import NordykeImage from '../images/Nordyke.jpeg' // Import image directly

const ClientStories = () => (
  <Layout head={{ title: 'Funny Client Stories' }}>
    <CommonHero pageTitle="Funny Client Stories" />
    <div className="container px-5 mx-auto client-stories-section mt-5 mb-5">
      <div className="row mb-5">
        <div className="col-12">
          <h2 className="text-center mb-4 h3 font-weight-bold">
            Client Stories We Love to Share
          </h2>
          <p className="text-center mb-0 mx-auto" style={{ maxWidth: '800px' }}>
            At Brian Boals Real Estate Team, we value the relationships we build
            with our clients. Here are some fun stories from our years of
            helping people find their dream homes.
          </p>
        </div>
      </div>
      <div className="row">
        {clientStories.map((story, index) => {
          // Get the appropriate image based on the story ID
          const getStoryImage = storyId => {
            if (storyId === 'jc-heather-nordyke') {
              return NordykeImage
            }
            return null
          }

          const storyImage = getStoryImage(story.id)

          return (
            <div key={story.id} className="col-md-6 col-lg-4 mb-5">
              <div className="card h-100 client-card">
                <div
                  className="card-img-top"
                  style={{
                    height: '240px',
                    backgroundImage: `url(${storyImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                  }}
                />
                <div className="card-body d-flex flex-column p-4">
                  <h3 className="card-title mb-3">{story.title}</h3>
                  <p className="card-text flex-grow-1">
                    {story.shortDescription}
                  </p>
                  <Link
                    to={`/funny-client-stories/${story.id}`}
                    className="alink hvr-shadow my-1 text-center primary-button btn-before border-none d-block mt-auto"
                  >
                    Read Full Story
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      {clientStories.length === 0 && (
        <div className="row">
          <div className="col-12 text-center py-5">
            <p>More client stories coming soon!</p>
          </div>
        </div>
      )}

      <div className="row mt-5">
        <div className="col-12 text-center">
          <p className="mb-4 mx-auto" style={{ maxWidth: '800px' }}>
            Relationships are important to us. We love building long-lasting
            connections with our clients and helping them find the perfect home.
            Would you like to be our next client story?
          </p>
          <div className="position-relative mb-3">
            <a
              href="tel:7194599955"
              className="alink hvr-shadow my-1 text-center primary-button btn-before border-none"
            >
              Call Us at (719) 459-9955
            </a>
          </div>
          <a
            href="mailto:brianboals@gmail.com"
            className="alink hvr-shadow text-center secondary-button btn-before"
            role="button"
          >
            Email Us at brianboals@gmail.com
          </a>
        </div>
      </div>
    </div>

    <style jsx>{`
      .client-card {
        border-radius: 10px;
        overflow: hidden;
        transition: all 0.3s ease;
        border: none;
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        margin-bottom: 2rem;
      }
      .client-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
      }
      .card-img-top {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .card-title {
        color: #012d6f;
        font-weight: 600;
        font-size: 1.3rem;
      }
      .card-text {
        color: #555;
        line-height: 1.6;
      }
      .client-stories-section {
        padding-top: 2rem;
        padding-bottom: 3rem;
      }
      .card-body {
        padding: 1.5rem !important;
      }
    `}</style>
  </Layout>
)

export default ClientStories
